<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" class="mr-16" @click="certificateVisible=true"><i class="e-icon view"></i>查看证书</a-button>
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
    </template>

    <a-spin :spinning="loading1">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">基础信息</h3>
        </div>
        <div class="module-main bdm" style="padding-bottom: 16px;">
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">企业名称：</span>
                <span class="detail-value">{{detailData.companyName || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">企业类型：</span>
                <span class="detail-value">{{detailData.companyTypeText}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">营业执照：</span>
                <span class="detail-value">{{detailData.companyLicenseNumber || '-'}}</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">营业执照图片：</span>
                <a-button v-if="detailData.companyLicenseImageUrl" type="link" style="padding: 0" @click="viewBigImage">点击查看营业执照照片</a-button>
                <span class="detail-value" v-else>-</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">负责人姓名：</span>
                <span class="detail-value">{{detailData.companyChargePerson || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">负责人身份号码：</span>
                <span class="detail-value">{{detailData.companyChargePersonIdNumber || '-'}}</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">公司地址：</span>
                <span class="detail-value">{{detailData.companyFullAddress || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">工厂地址：</span>
                <span class="detail-value">{{detailData.factoryFullAddress || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">联系人员：</span>
                <span class="detail-value">{{detailData.companyContactPerson || '-'}}</span>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="8">
            <a-col :span="8">
              <div class="detail-item">
                <span class="detail-label">联系电话：</span>
                <span class="detail-value">{{detailData.companyContactPhone || '-'}}</span>
              </div>
            </a-col>
            <a-col :span="16">
              <div class="detail-item">
                <span class="detail-label">业务类型：</span>
                <span class="detail-value">{{businessTypesStr}}</span>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>

    <a-spin :spinning="loading2">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">业务信息</h3>
        </div>
        <div class="module-main bdm" style="padding-bottom: 32px;">
          <!-- 企业无业务信息时展示 -->
          <div class="no-business" v-if="selectedBusinessTypes.length == 0">
            <a-empty :image="simpleImage" description="当前企业未选择业务类型" />
          </div>
          <!-- 企业有业务信息时展示 -->
          <div class="business-show-wrap"  v-else>
            <a-radio-group ref="radioGroup" v-model="currentBusinessId" button-style="solid" @change="businessChange">
              <a-radio-button :value="business.id" v-for="business in selectedBusinessTypes" :key="business.id">
                {{business.name}}
              </a-radio-button>
            </a-radio-group>

            <div class="business-show">
              <div class="business-item" v-show="currentBusinessId === business.id" v-for="business in selectedBusinessTypes" :key="business.id">
                <!-- :style="{width: radioGroupWidth + 'px'}" -->
                <div v-if="!business.isEdited">
                  <!-- <span class="detail-label">当前企业未编辑相关属性</span> -->
                  <a-empty :image="simpleImage" description="当前企业未编辑相关属性" />
                </div>
                <div style="padding: 32px 0 0;" v-else>
                  <div class="detail-item" v-for="cont in business.content" :key="cont.key">
                    <span class="detail-label">{{cont.key}}：</span>
                    <span class="detail-value">{{cont.showText}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>

    <a-spin :spinning="loading3">
      <div class="mp-detail-module">
        <div class="module-top">
          <h3 class="mod-ttile">企业用户</h3>
        </div>
        <div class="module-main">
          <!-- 企业无业务信息时展示 -->
          <div class="no-business" v-if="userData.length == 0">
            <a-empty :image="simpleImage" description="暂无企业用户" />
          </div>
          <div style="width: 90%" v-if="userData.length > 0">
            <a-table :columns="userColumns" :data-source="userData" rowKey="userId" bordered :pagination="false">
              <template slot="userStatus" slot-scope="text">
                <a-badge v-if="text == 'y'" color="green" text="有效" />
                <a-badge v-if="text == 'n'" color="#999" text="无效" />
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </a-spin>
    <!-- 查看大图 -->
    <a-modal v-model="imgVisible" title="营业执照照片" centered :width="800" :footer="null">
      <img style="width: 100%;" :src="viewImgUrl">
    </a-modal>

    <!-- 查看会员证书 -->
    <a-modal v-model="certificateVisible" title="证书照片" centered :width="806" :footer="null">
      <div class="certificate-img-box">
        <span class="title">{{detailData.companyName}}</span>
        <span class="code">No:{{detailData.certificateNumber}}</span>
      </div>
    </a-modal>
  </page-head-wrapper>
</template>

<script>
import { Empty } from 'ant-design-vue'
import { 
  approvedCompanyDetail,
  templateList,
  enterpriseUserList,
  companyBusinessList
} from '@/api/company'
import { relatedApps } from '../../user/user-config'
import { buildThumborUrl } from '@/utils/tools'
const userColumns = [
  {
    title: '用户昵称(姓名)',
    align: 'center',
    dataIndex: 'userNickname'
  },
  {
    title: '联系电话',
    align: 'center',
    dataIndex: 'userCellphone',
  },
  {
    title: '关联应用',
    align: 'center',
    dataIndex: 'associatedApplications',
    ellipsis: true
  },
  {
    title: '状态',
    align: 'center',
    dataIndex: 'userStatus',
    scopedSlots: { customRender: 'userStatus' },
  }
]
export default {
  name: 'static-data-company-member-detail',
  keep_alive_tab: true,
  data() {
    return {
      // 空状态图片类型
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      
      id: '',
      currentPath: '',

      loading1: false,
      loading2: false,
      loading3: false,
      imgVisible: false,
      certificateVisible: false,
      viewImgUrl: '',

      // 基础信息
      detailData: {
        companyStatus: '',

        companyName: '',
        companyType: '',
        companyTypeText: '',

        companyLicenseNumber: '',
        companyLicenseImage: '',
        companyLicenseImageUrl: '',
        
        companyChargePerson: '',
        companyChargePersonIdNumber: '',


        companyFullAddress: '',
        companyProvince: '',
        companyCity: '',
        companyDistrict: '',
        companyAddress: '',
        
        companyContactPerson: '',
        companyContactPhone: '',

        factoryFullAddress: '',
        factoryProvince: '',
        factoryCity: '',
        factoryDistrict: '',
        factoryAddress: '',

        businessTypes: [],
      },
      businessTypesArr: [],
      businessTypesStr: '',
      
      templateList: [], // 模板列表

      // 用户上次保存的业务类型及数据
      lastEditBusinessTypes: {},

      // 业务类型
      radioGroupWidth: 200,
      selectedBusinessTypes: [
        // {
        //   id: 1,
        //   name: '面辅料供应',
        //   value: 'a',
        //   hasProps: true, // 是否配置属性
        //   isEdited: true,     // 企业是否填写
        //   content: [
        //     {
        //       key: '供应品类',
        //       value: '面料、丝线'
        //     },
        //     {
        //       key: '生产产能',
        //       value: '100吨/天'
        //     }
        //   ]
        // },
        // {
        //   id: 2,
        //   name: '产品设计',
        //   value: 'b',
        //   hasProps: false, // 是否配置属性
        //   isEdited: false,     // 企业是否填写
        //   content: []
        // },
        // {
        //   id: 3,
        //   name: '软件服务',
        //   value: 'c',
        //   hasProps: true, // 是否配置属性
        //   isEdited: false,     // 企业是否填写
        //   content: []
        // },
        // {
        //   id: 4,
        //   name: '硬件服务',
        //   value: 'd',
        //   hasProps: true, // 是否配置属性
        //   isEdited: true,     // 企业是否填写
        //   content: [
        //     {
        //       key: '硬件类型',
        //       value: '电脑、手机、锤子、螺丝'
        //     },
        //     {
        //       key: '生产产能',
        //       value: '100件/天'
        //     }
        //   ]
        // },
      ],
      currentBusinessId: '',

      // 企业用户
      userColumns,
      userData: [],

    }
  },
  created() {
    this.id = this.$route.query.id
    this.currentPath = this.$route.fullPath
    this.approvedCompanyDetail()
    this.getEnterpriseUserList()
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.radioGroup) {
        this.radioGroupWidth = this.$refs.radioGroup.$el.offsetWidth
      }
      
    })
  },
  methods: {

    // 获取企业详情
    approvedCompanyDetail() {
      this.loading1 = true
      approvedCompanyDetail(this.id).then(res => {
        if (res.code == 200) {
          let companyTypeText = res.data.companyType == 1 ? '工商型': res.data.companyType == 2 ? '个人型' : '-'
          this.detailData = {
            ...res.data,
            companyTypeText,
            companyLicenseImageUrl: buildThumborUrl(res.data.companyLicenseImage)
          }
          // 更新标题
          if (res.data.companyName) {
            this.$store.dispatch('updateTabTitle', {
              force: true,
              path: this.currentPath,
              title: res.data.companyName
            })
          }

          try {
            this.businessTypesArr = this.detailData.businessTypes.split(',')
          } catch (error) {
            this.businessTypesArr = []
          }
          
          // 获取模板数据
          this.getTemplateList()
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading1 = false
      })
    },
    // 获取业务类型列表
    getTemplateList() {
      templateList({parentId: ''}).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.templateList = res.data.filter(item => item.status == 1)
          this.calcBusinessTypes()
          this.getCompanyBusinessList()
        } else {
          this.$message.error(res.msg || '获取业务类型出错')
        }
      }).catch(err => {
        console.warn(err)
      })
    },
    // 计算企业已选择业务， id => 名称
    calcBusinessTypes() {
      let businessTypesStr = ''
      let selectedBusinessTypes = []

      if (this.detailData.businessTypes && this.templateList.length > 0) {
        let businessTypes = this.detailData.businessTypes.split(',')
        console.log(businessTypes)
        selectedBusinessTypes =  this.templateList.filter(item => businessTypes.indexOf(''+item.id) >= 0 )
        console.log(selectedBusinessTypes)
        businessTypesStr = selectedBusinessTypes.map(item => item.name).join('、') || '-'
      } else {
        selectedBusinessTypes = []
        businessTypesStr = '-'
      }

      this.businessTypesStr = businessTypesStr
      this.selectedBusinessTypes = selectedBusinessTypes
      if (this.selectedBusinessTypes.length > 0) {
        this.currentBusinessId = this.selectedBusinessTypes[0].id
      }
    },

    // 查看营业执照大图
    viewBigImage() {
      this.imgVisible = true
      this.viewImgUrl = this.detailData.companyLicenseImageUrl
    },

    // 获取企业用户列表
    getEnterpriseUserList() {
      this.loading3 = true
      enterpriseUserList({
        companyId: this.id
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.userData  = res.data.map(item => {
            let associatedApplications = item.associatedApplications.split(',').map(key => {
              return relatedApps[key]
            }).join('、')
            return {
              ...item,
              userNickname: item.userNickname || '-',
              associatedApplications,
            }
          })
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.loading3 = false
      })
    },
  

    // 刷新操作
    onRefresh() {
      this.approvedCompanyDetail()
      this.getEnterpriseUserList()
    },

    // 详情 - 获取业务信息
    getCompanyBusinessList() {
      companyBusinessList({companyId: this.id}).then(res => {
        console.log(res)
        if (res.code == 200) {
          if (res.data.length == 0) {
            console.log(this.selectedBusinessTypes)
            this.selectedBusinessTypes = this.selectedBusinessTypes.map(item => {
              return {
                ...item,
                isEdited: false
              }
            })
          } else if (res.data.length > 0) {
            let content = JSON.parse(res.data[0].content)
            console.log(this.selectedBusinessTypes)
            console.log(content)
            let contentObj = {}
            content.forEach(item => {
              contentObj[item.id] = item
            })
            this.lastEditBusinessTypes = contentObj
            let firstId = this.selectedBusinessTypes[0].id
            if (contentObj[firstId].content && contentObj[firstId].content.length > 0) {
              this.selectedBusinessTypes[0].isEdited = true
              this.selectedBusinessTypes[0].content = contentObj[firstId].content 

              this.selectedBusinessTypes = [...this.selectedBusinessTypes]
            }
          }
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      })
    },
    businessChange(e) {
      console.log('businessChange', e)
      console.log('businessChange', e)
      let id = e.target.value
      let index = this.selectedBusinessTypes.findIndex(item => item.id == id)
      if (this.lastEditBusinessTypes[id].content && this.lastEditBusinessTypes[id].content.length > 0) {
        this.selectedBusinessTypes[index].isEdited = true
        this.selectedBusinessTypes[index].content = this.lastEditBusinessTypes[id].content 
      }
    }

  }
}
</script>

<style lang="less">
.address-cascader {
  border-radius: 2px 0 0 2px;
  .ant-input {
    border-right-color: #eee;
    border-radius: 2px 0 0 2px;
  }
}
</style>